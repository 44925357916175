<template>
  <main class="page">
    <header class="page__header p-2">
      <img :src="require('@/assets/images/pages/logotipo.svg')" alt="" aria-hidden="true" class="d-block" style="height: 100%; width: auto" />
    </header>
    <div class="page__content py-4">
      <div class="mx-0 pb-3 row border-bottom align-items-center">
        <div class="col">
          <h1 class="font-weight-bold">La mia libreria</h1>
        </div>
        <div class="col-auto text-right">
          <b-button variant="link" class="text-dark p-0" @click="$emit('logout')">
            <i class="piclink-icon icon-sign-out" style="font-size: 30px" aria-hidden="true"></i>
            <span class="sr-only">Logout</span>
          </b-button>
        </div>
      </div>
      <router-link v-for="project in projects" :to="{ name: 'scan-resource', params: { projectId: project.id } }" :key="project.id">
        <div class="row mx-0 py-4 border-bottom">
          <div class="col-3">
            <b-img thumbnail class="border-0 p-0" :src="project.thumbSrc" :alt="project.name" />
          </div>
          <div class="col pl-0">
            <p class="h3 text-dark mb-0">{{ project.title }}</p>
          </div>
        </div>
      </router-link>
    </div>
  </main>
</template>

<script>

export default {
  data () {
    return {
      projects: [],
    };
  },

  mounted () {
    this.projects = this.$storage.get('projects') || [];
  },
};

</script>
